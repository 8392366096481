#bg-video {
    object-fit: cover;
    width: 100vw;
    height: 400px;
    position: relative;
    top: 0;
    left: 0;
    z-index: -1;
}

.app-header {
h1 {
    text-align: center;
    font-size: 3.2rem;
    text-shadow: 0px 0px 5px rgba(0,0,0,.8);
    position: absolute;
    width: 100% !important;
    margin: 50px auto;
  }
  p {
    position: absolute;
    text-align: center;
    width: 100%!important;
    margin: 250px auto;
    text-shadow: 0px 0px 5px rgba(0,0,0,.8);
  }
}


@media screen and (min-width: 768px) {

.app-header {
  h1 {
    font-size: 4.8rem;  
  }
}
}