.services {
    box-sizing: border-box;
  
    h2 {
      width: 100%;
    }
    .detail-packages{
      display: flex;
      flex-direction: column;
      width: 100%;
  
      .detail-option {
        display: flex;
        flex-direction: column;
        margin: 3%;
        background: #fff;
        color: #444;
        padding: 15px;
        box-shadow: 5px 5px 5px rgba(0,0,0,.5);

        h3 {
            color: #222;
            width: 100%;
            position: relative;

            span {
                position: absolute;
                right:0;
                color: rgba(9,9,121,1);
            }
        }
      }
    }
    
  }
  .other-services {
    margin: 50px;
    margin-top: 10px;
    padding: 25px;
    background :#fff;
    color: #444;

    h3 {
        color: #222;
    }
  }

  @media screen and (min-width: 768px) {
    .services {
  
        h2 {
          width: 100%;
        }
        .detail-packages{
            flex-direction: row;
      
          .detail-option {
            width: 30%;
            display: flex;
            flex-direction: column;
            margin: 3%;
            background: #fff;
            color: #444;
            padding: 15px;
    
            h3 {
                color: #222;
                width: 100%;
            }
          }
        }
        
      }
    
    
}