
body {
  box-sizing: border-box;
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  background: #101010;
  color: #eee;
  margin: 0;
  overflow-x: hidden;
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 50%, rgba(0,212,255,1) 100%);
}

header {

}

.main-container {
  box-sizing: border-box;
  max-width: 90vw;
  margin: 0 auto;
  min-height: calc(100vh - 200px);

h2 {
  margin-top: 50px;
};

  p {
    line-height: 2rem;
  }
}

.photo-list {
  display:flex;
  flex-direction: row;
  
  img {
    width: 27%;
    margin: 3%;
    border-radius: 15px;
  }
}




footer {
  width: 100vw;
  min-height: 150px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.address {
  margin: 50px;
  text-align: center;
}

.address a {
  color: #fff;
}

.copyright {
  padding: 25px;
}

