.threecolumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    justify-content: stretch;

    .column {
        width: 100%;
    }

    .box {
        text-align: center;
        display: block;
        backdrop-filter: blur(20px);
        padding: 25px;
        backdrop-filter: hue-rotate(-30deg);
        border-radius: 15px;
        color: #fff;
        text-decoration: none;
        img{
            border-radius: 50%;
            width: 80%;
        }

        .column-button {
            border-radius: 15px;
            background-color: rgba(255,255,255,.8);
            color: #111;
            font-weight: bold;
            box-shadow: 3px 3px 15px #111;
            opacity: 0;
            transition: 0.5s ease all;
            margin-top: 50px;
        }

        &:hover {
            animation: hue 2s infinite;

            .column-button {
                opacity: 1;
            }
        }
    }

    
}


@media screen and (min-width: 768px) {

    .threecolumn {
        flex-direction: row;
        flex-wrap: nowrap;

        .column {
            width: 30%;
            margin: 1.5%;
            align-self: stretch;
        }

        .box {
            height: 90%;
        }
    }
    
}


@keyframes hue {
    0% {
        backdrop-filter: hue-rotate(-30deg);
    }
    50% {
        backdrop-filter: hue-rotate(30deg);
    }
    100% {
        backdrop-filter: hue-rotate(-30deg);
    }
  }