.contact {

    background: #333;
 
    .contact-container {
        margin: 50px 0;
        padding: 25px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    h2 {
        width: 100%;
        padding: 15px;
        text-align: center;
        font-size:3.2rem;
    }

    .contact-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        p {
            font-size: 1.4rem;
        }
        svg {
            fill: #fff;
            cursor: pointer;
            height: 45px;
            margin-top: 50px;
            transition: 0.5s ease all;

            &:hover {
                fill: hotpink;
            }
        }
    }

    .phone, .email {
        color: #fff;
        transition: 0.5s ease all;
        font-size: 1.4rem;

        &:hover {
            color: hotpink;
        }
    }

    .contact-form {
        margin: 50px auto;
        text-align: left;
        max-width: 800px;
      
        label {
            font-weight: 700;
            font-size: 1.2rem;
            padding: 20px 0 10px 5px;
        }
      
        input,
        textarea {
            border: 1px solid #CFCFCF;
            border-radius: 5px;
            padding: 10px;
            font-size: 1.2rem;
        }
      
        .contact {
            display: flex;
            flex-direction: column;
        }
      
        .button {
            margin: 25px 0;
            padding: 20px;
            color: #333;
            background-color: #fff;
            transition: .25s ease all;
            border: 0;
            cursor: pointer;
      
            &:hover {
                background-color: pink;
                box-shadow: 3px 3px 15px rgba(0,0,0,.8);
            }
        }
      }
      

}

@media screen and (min-width: 768px) {

    .contact {
        width: 100%;

        .contact-container {
            padding: 50px;
            margin: 0 auto;
        }
        .contact-form,
        .contact-details {
            width: 50%;
            max-width: 550px;
            margin: 0 auto;
        }
    }

    }